import { FunnelsProvider } from '../store/funnels'
import { UserProvider } from '../store/user'
import { Funnels } from './funnels'

const Index = () => {
	return (
		<UserProvider>
			<FunnelsProvider>
				<Funnels />
			</FunnelsProvider>
		</UserProvider>
	)
}

export default Index
